import logo from "./starworksLogo.png";
import starhoundImg from "./assets/starhound.png";
import timeworksImg from "./assets/timeworks.png";
import discordLogo from "./assets/socialLogos/discordLogo.png";
import "./App.css";
import Button from "react-bootstrap/Button";
import { Header } from "./components";
import BlogFeatureDisplay from "./components/BlogFeatureDisplay";
import { BlogPost, blankBlogPost, getBlogPosts } from "./util";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Footer from "./components/Footer";
import { LandingPage } from "./components/LandingPage";
import { useWindowDimensions } from "./hooks/useWindowDimensions";

function App() {
  const navigate = useNavigate();
  const screenSize = useWindowDimensions();

  const emptyBlogs: BlogPost[] = [];
  const [featuredBlogs, setFeaturedBlogs] = useState(emptyBlogs);
  const [newsSection, setNewsSection] = useState(<></>);

  useEffect(() => {
    retrieveData();
  }, []);

  useEffect(() => {
    setNewsSection(
      featuredBlogs.length > 0 ? (
        <div className="color-backdrop" style={{ width: "100%" }}>
          <div
            className="d-flex"
            style={{
              justifyContent: "center",
              alignItems: "center",
              gap: "3rem",
              marginTop: "1rem",
              padding: "0rem 1rem",
              flexDirection: screenSize.width > 990 ? "row" : "column",
            }}
          >
            {featuredBlogs.length > 0 && (
              <BlogFeatureDisplay blogPost={featuredBlogs[0]} />
            )}
            {featuredBlogs.length > 1 && (
              <BlogFeatureDisplay blogPost={featuredBlogs[1]} />
            )}
            {featuredBlogs.length > 2 && (
              <BlogFeatureDisplay blogPost={featuredBlogs[2]} />
            )}
          </div>
          <div className="m-3"></div>
        </div>
      ) : (
        <></>
      )
    );
  }, [featuredBlogs, screenSize]);

  async function retrieveData() {
    const blogList = await getBlogPosts();
    const sorted = blogList
      .filter((p) => p.published)
      .sort((a, b) => {
        if ((a.featured && b.featured) || (!a.featured && !b.featured))
          return b.creationDate.getTime() - a.creationDate.getTime();
        return a.featured ? -1 : 1;
      });
    const featured = [];
    if (sorted.length > 0) {
      featured.push(sorted[0]);
    }
    if (sorted.length > 1) {
      featured.push(sorted[1]);
    }
    if (sorted.length > 2) {
      featured.push(sorted[2]);
    }
    setFeaturedBlogs(featured);
  }

  const starhoundFakeBlog: BlogPost = {
    id: "",
    title: "STARHOUND",
    authors: [],
    shortText:
      "The forces of the cat empire have dug their hairy paws into every corner of the galaxy! You play as Pickles, a seasoned pilot of the dog resistance.",
    image: starhoundImg,
    content: "",
    creationDate: new Date(),
    publishDate: new Date(),
    published: false,
    pinned: false,
    featured: false,
  };

  const timeworksFakeBlog: BlogPost = {
    id: "",
    title: "Timeworks",
    authors: [],
    shortText:
      "The goal of every level is to complete your quota by assembling products and delivering them within a limited time. The twist is, you can time travel.",
    image: timeworksImg,
    content: "",
    creationDate: new Date(),
    publishDate: new Date(),
    published: false,
    pinned: false,
    featured: false,
  };

  return (
    <>
      <Header />
      <div className="App-backdrop">
        <LandingPage />
        {newsSection}
      </div>
      <Footer />
    </>
  );
}

export default App;
