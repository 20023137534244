import { User } from "firebase/auth";
import { auth, db } from "./firebase";
import {
  doc,
  getDoc,
  query,
  updateDoc,
  setDoc,
  collection,
  getDocs,
  addDoc,
} from "firebase/firestore";

export class StarworksUser {
  permissionLevel: number = 0;
  userDoc;
  constructor(public authUser: User) {
    this.userDoc = doc(db, "userdata", this.authUser.uid);
  }

  async loadUserData() {
    const docSnap = await getDoc(this.userDoc);
    if (!docSnap.exists()) {
      this.permissionLevel = -1;
      return;
    }

    this.permissionLevel = docSnap.data().permissionLevel ?? 0;
  }

  async updateUserData(newData: any) {
    const updatedDoc: any = {};

    if (typeof newData.firstName === "string") {
      updatedDoc.firstName = newData.firstName;
    }
    if (typeof newData.lastName === "string") {
      updatedDoc.lastName = newData.lastName;
    }
    if (typeof newData.teamRole === "string") {
      updatedDoc.teamRole = newData.teamRole;
    }

    if (this.permissionLevel === -1) {
      await setDoc(this.userDoc, updatedDoc);
    } else {
      await updateDoc(this.userDoc, updatedDoc);
    }
    await this.loadUserData();
  }
}

export class Bio {
  constructor(
    public docId: string,
    public displayName: string,
    public teamRole: string,
    public description: string,
    public image: string,
    public links: BioLink[],
    public color1 = "#383e48",
    public color2 = "#383e48",
    public order = 0,
    public show = true
  ) {}
}

export class BioLink {
  constructor(
    public url: string,
    public icon: string,
    public invert: boolean = false
  ) {}
}

export async function getMemberBio(id: string) {
  const docRef = doc(db, "/projectdata/website/memberBios", id);
  const bioDoc = await getDoc(docRef);
  if (!bioDoc) return null;
  const docData = bioDoc.data();
  if (!docData) return null;

  const links = docData.linkUrls.map((v: string, i: number) => {
    return new BioLink(v, docData.linkIcons[i], docData.linkInverts[i]);
  });
  return new Bio(
    bioDoc.id,
    docData.displayName,
    docData.teamRole,
    docData.description,
    docData.image,
    links,
    docData.color1 ?? "#383e48",
    docData.color2 ?? "#383e48",
    docData.order,
    docData.show
  );
}

export async function getMemberBios() {
  const bioList: Bio[] = [];
  const bioQuery = collection(db, "/projectdata/website/memberBios");
  const snap = await getDocs(bioQuery);

  snap.docs.forEach((bioDoc) => {
    const links = bioDoc.data().linkUrls.map((v: string, i: number) => {
      return new BioLink(
        v,
        bioDoc.data().linkIcons[i],
        bioDoc.data().linkInverts[i]
      );
    });
    bioList.push(
      new Bio(
        bioDoc.id,
        bioDoc.data().displayName,
        bioDoc.data().teamRole,
        bioDoc.data().description,
        bioDoc.data().image,
        links,
        bioDoc.data().color1 ?? "#383e48",
        bioDoc.data().color2 ?? "#383e48",
        bioDoc.data().order,
        bioDoc.data().show
      )
    );
  });
  return bioList;
}

export function starworksLogoLink() {
  return "https://img.itch.zone/aW1nLzEyMDQ4ODk1LnBuZw==/original/gvz9rZ.png";
}

export const blankUserBio = new Bio(
  "",
  "New Member",
  "Member",
  "This is filler text while the real data loads in.",
  starworksLogoLink(),
  []
);

export async function createMemberBio(id: string) {
  const bioDoc = doc(db, "/projectdata/website/memberBios", id);
  const emptyUserBio = {
    show: false,
    order: 999,
    displayName: "New Member",
    teamRole: "Member",
    description: "Hi I'm new :)",
    image: starworksLogoLink(),
    linkUrls: [],
    linkIcons: [],
    linkInverts: [],
    color1: "#383e48",
    color2: "#383e48",
  };
  await setDoc(bioDoc, emptyUserBio);
}

export function stripBioData(bio: Bio) {
  return {
    displayName: bio.displayName,
    teamRole: bio.teamRole,
    description: bio.description,
    image: bio.image,
    linkUrls: bio.links.map((link) => link.url),
    linkIcons: bio.links.map((link) => link.icon),
    linkInverts: bio.links.map((link) => link.invert),
    color1: bio.color1,
    color2: bio.color2,
  };
}

export async function updateMemberBio(id: string, data: any) {
  const bioDoc = doc(db, "/projectdata/website/memberBios", id);
  await updateDoc(bioDoc, data);
}

export type UserData = {
  id: string;
  firstName: string;
  lastName: string;
  teamRole: string;
  permissionLevel: number;
  notes?: string;
};

export const blankUserData: UserData = {
  id: "",
  firstName: "",
  lastName: "",
  teamRole: "",
  permissionLevel: 0,
};

export async function getUsersData() {
  const userList: UserData[] = [];
  const userQuery = collection(db, "/userdata");
  const snap = await getDocs(userQuery);

  snap.docs.forEach((doc) => {
    userList.push({
      id: doc.id,
      firstName: doc.data().firstName ?? "",
      lastName: doc.data().lastName ?? "",
      teamRole: doc.data().teamRole ?? "",
      permissionLevel: doc.data().permissionLevel ?? 0,
      notes: doc.data().notes ?? "",
    });
  });

  return userList;
}

export function stripUserData(userData: UserData) {
  const rv: any = {
    firstName: userData.firstName,
    lastName: userData.lastName,
    teamRole: userData.teamRole,
    permissionLevel: userData.permissionLevel,
  };
  if (userData.notes) {
    rv.notes = userData.notes;
  }
  return rv;
}

export async function updateUserData(id: string, data: any) {
  const userDoc = doc(db, "/userdata", id);
  await updateDoc(userDoc, data);
}

export type BlogPost = {
  id: string;
  title: string;
  authors: string[];
  shortText: string;
  image: string;
  content: string;
  creationDate: Date;
  publishDate: Date;
  published: boolean;
  pinned: boolean;
  featured: boolean;
};

export const blankBlogPost: BlogPost = {
  id: "",
  title: "",
  authors: [""],
  shortText: "",
  image: starworksLogoLink(),
  content: "This is filler text while the real data loads in",
  creationDate: new Date(0),
  publishDate: new Date(0),
  published: false,
  pinned: false,
  featured: false,
};

export async function getBlogPost(id: string) {
  const docRef = doc(db, "/projectdata/website/blogPosts", id);
  const blogPostDoc = await getDoc(docRef).catch(() => null);
  if (!blogPostDoc) return null;
  const docData = blogPostDoc.data();
  if (!docData) return null;

  const rv: BlogPost = {
    id: blogPostDoc.id,
    title: docData.title,
    authors: docData.authors,
    shortText: docData.shortText,
    image: docData.image,
    content: docData.content,
    creationDate: new Date(docData.creationDate),
    publishDate: new Date(docData.publishDate),
    published: docData.published,
    pinned: docData.pinned ?? false,
    featured: docData.featured ?? false,
  };

  return rv;
}

export async function getBlogPosts() {
  const blogPosts: BlogPost[] = [];
  const blogQuery = collection(db, "/projectdata/website/blogPosts");
  const snap = await getDocs(blogQuery);

  snap.docs.forEach((doc) => {
    blogPosts.push({
      id: doc.id,
      title: doc.data().title ?? "",
      authors: doc.data().authors ?? [""],
      shortText: doc.data().shortText ?? "",
      image: doc.data().image ?? starworksLogoLink(),
      content: doc.data().content ?? "",
      creationDate: new Date(doc.data().creationDate ?? 0),
      publishDate: new Date(doc.data().publishDate ?? 0),
      published: doc.data().published ?? false,
      pinned: doc.data().pinned ?? false,
      featured: doc.data().featured ?? false,
    });
  });

  return blogPosts;
}

export async function getAuthorDetails(id: string) {
  if (!id || id === "")
    return new Bio("", "Unknown User", "", "", starworksLogoLink(), []);
  return (
    (await getMemberBio(id)) ??
    new Bio("", id, "Member", "", starworksLogoLink(), [])
  );
}

export async function listAuthors(authors: string[]) {
  if (authors.length === 0) return "STAFF";
  const authorBio = await getAuthorDetails(authors[0]);
  if (authors.length === 1) return authorBio.displayName;
  return `${authorBio.displayName} +${authors.length - 1}`;
}

export function stripBlogPost(data: BlogPost) {
  const rv: any = { ...data };
  rv.creationDate = data.creationDate.getTime();
  rv.publishDate = data.publishDate.getTime();
  delete rv.id;
  return rv;
}

export async function createBlogPost(author: string) {
  const blogCollection = collection(db, "/projectdata/website/blogPosts");
  const blogData: any = { ...blankBlogPost };

  blogData.authors = [author];
  blogData.creationDate = Date.now();
  blogData.publishDate = Date.now();
  blogData.content = "";
  delete blogData.id;

  return await addDoc(blogCollection, blogData);
}

export async function updateBlogPost(id: string, data: any) {
  const blogDoc = doc(db, "/projectdata/website/blogPosts", id);
  await updateDoc(blogDoc, data);
}
