import Footer from "./Footer";
import Header from "./Header";

const Contact = () => {
  return (
    <>
      <Header />
      <div className="App-backdrop vertical-center">
        <div>
          If you have business inquires feel free to reach out at the email
          address below.
        </div>
        <div>StarworksStudios8@gmail.com</div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
