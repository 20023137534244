import { useState, useEffect, useContext } from "react";
import Header from "./Header";
import MemberBio from "./MemberBio";
import { getMemberBios, Bio, BioLink } from "../util";
import Footer from "./Footer";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import Author from "./Author";

const oneColWidth = 1390;
const mobileWidth = 720;

const Team = () => {
  const [allBios, setBioList]: [Bio[], any] = useState([]);
  const [biosLeft, setBiosLeft] = useState([<></>]);
  const [biosRight, setBiosRight] = useState([<></>]);
  const screenSize = useWindowDimensions();

  // get bios on page load
  useEffect(() => {
    getMemberBios().then((bioList) => {
      bioList = bioList.filter((v) => v.show).sort((a, b) => a.order - b.order);
      setBioList(bioList);

      const left = bioList
        .filter((v, i) => i % 2 === 1)
        .map((v, i) => (
          <MemberBio bio={v} imgLeft={true} key={v.displayName + (2 * i + 1)} />
        ));
      const right = bioList
        .filter((v, i) => i % 2 === 0)
        .map((v, i) => (
          <MemberBio bio={v} imgLeft={false} key={v.displayName + 2 * i} />
        ));
      setBiosLeft(left);
      setBiosRight(right);
    });
  }, []);

  // order bios
  useEffect(() => {
    if (screenSize.width > mobileWidth) {
      const left = allBios
        .filter((v, i) => i % 2 === 1)
        .map((v, i) => (
          <MemberBio bio={v} imgLeft={true} key={v.displayName + (2 * i + 1)} />
        ));
      const right = allBios
        .filter((v, i) => i % 2 === 0)
        .map((v, i) => (
          <MemberBio bio={v} imgLeft={false} key={v.displayName + 2 * i} />
        ));

      if (screenSize.width > oneColWidth) {
        setBiosLeft(left);
        setBiosRight(right);
      } else {
        const zippedBios = interleaveArrays(left, right);
        setBiosLeft(zippedBios);
        setBiosRight([]);
      }
    } else {
      const domBios = allBios.map((v, i) => (
        <Author bio={v} key={v.displayName + (2 * i + 1)} />
      ));
      setBiosLeft(domBios);
      setBiosRight([]);
    }
  }, [allBios, screenSize]);

  return (
    <>
      <Header />
      <div className="App-backdrop dotted-backdrop">
        <div className="d-flex">
          <div
            className="d-flex"
            style={{
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "95vw",
            }}
          >
            <h1 className="text-center" style={{ fontSize: "4rem" }}>
              Meet the Team
            </h1>
            {biosLeft}
          </div>
          <div
            className="d-flex"
            style={{
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "95vw",
            }}
          >
            {biosRight}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Team;

function interleaveArrays<T1, T2>(array1: T1[], array2: T2[]) {
  const result: (T1 | T2)[] = [];
  const maxLength = Math.max(array1.length, array2.length);

  for (let i = 0; i < maxLength; i++) {
    if (i < array1.length) {
      result.push(array1[i]);
    }
    if (i < array2.length) {
      result.push(array2[i]);
    }
  }

  return result;
}
