import { useState } from "react";
import { Button, Form } from "react-bootstrap";

export const EmailCollect = () => {
  const [emailInput, setEmailInput] = useState("");
  const [submitState, setSubmitState] = useState(0);
  const [showInvalid, setShowInvalid] = useState(false);

  // https://docs.google.com/forms/d/e/1FAIpQLSdMInpNdPoaHy_gc9vhwqhPtwBiuTyEKioVRuKAAdWFw5h0_Q/viewform?usp=pp_url&entry.673669834=example@example.com
  const formId = "1FAIpQLSdMInpNdPoaHy_gc9vhwqhPtwBiuTyEKioVRuKAAdWFw5h0_Q";
  const entryIds = ["entry.673669834"];

  const buildUrl = (entries: string[]) => {
    if (entryIds.length !== entries.length) return "";
    let url = `https://docs.google.com/forms/d/e/${formId}/formResponse?&submit=Submit?usp=pp_url`;
    for (let i in entryIds) {
      let entryId = entryIds[i];
      let entry = entries[i];
      url += `&${entryId}=${entry}`;
    }
    return url;
  };

  if (submitState === 1) {
    // if is a valid email
    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(emailInput)) {
      let url = buildUrl([emailInput]);
      return (
        <iframe
          width="1"
          height="1"
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          onLoad={() => setSubmitState(2)}
        />
      );
    } else {
      setSubmitState(0);
      setShowInvalid(true);
    }
  }
  if (submitState === 2) {
    return <div>Thanks for submitting!</div>;
  }
  if (submitState === 3) {
    return <div>Something went wrong... Try again later.</div>;
  }

  return (
    <div style={{ height: "87px" }}>
      <div className="d-flex">
        <Form.Control
          id="emailInput"
          placeholder="Your Email Here"
          value={emailInput}
          onChange={(e) => {
            setEmailInput(e.target.value);
          }}
        />
        <Button onClick={() => setSubmitState(1)}>Submit</Button>
      </div>
      {showInvalid && (
        <div className="m-2 text-center" style={{ color: "red" }}>
          Invalid email address.
        </div>
      )}
    </div>
  );
};
