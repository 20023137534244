import { useEffect, useState } from "react";
import { Bio, BioLink } from "../util";

const MemberBio = ({
  bio,
  imgLeft,
  showEdit = false,
}: {
  bio: Bio;
  imgLeft: boolean;
  showEdit?: boolean;
}) => {
  const [bioLinks, setBioLinks] = useState([<></>]);

  useEffect(() => {
    setBioLinks(
      bio.links.map((link) => <MemberBioLink bioLink={link} key={link.url} />)
    );
  }, [bio]);

  const imgRender = (left: boolean) =>
    left === imgLeft ? <img src={bio.image} className="bioImg" /> : <></>;

  return (
    <div
      className={
        "memberBio d-flex " + (imgLeft ? "memberBioLeft" : "memberBioRight")
      }
      style={{
        backgroundImage: `linear-gradient(to ${imgLeft ? "right" : "left"}, ${
          bio.color1
        }, ${bio.color2})`,
      }}
    >
      {imgRender(true)}
      <div>
        <h2>{bio.displayName}</h2>
        <h5 style={{ fontStyle: "italic" }}>{bio.teamRole}</h5>
        <h5 style={{ width: "400px", height: "72px", fontSize: "1rem" }}>
          {bio.description}
        </h5>
        <div>{bioLinks}</div>
      </div>
      {imgRender(false)}
    </div>
  );
};
export default MemberBio;

const MemberBioLink = ({ bioLink }: { bioLink: BioLink }) => {
  const className = bioLink.invert ? "inverted" : "";
  return (
    <a href={bioLink.url} target="_blank" style={{ margin: "0.2rem" }}>
      <img
        className={className}
        src={bioLink.icon}
        style={{ width: "2rem", height: "2rem" }}
      />
    </a>
  );
};
