import { useNavigate } from "react-router-dom";
import { BlogPost } from "../util";

const BlogFeatureDisplay = ({
  blogPost,
  link,
  callToAction = "Read More",
}: {
  blogPost: BlogPost;
  link?: string;
  callToAction?: string;
}) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(link ? link : `/blog/${blogPost.id}`)}
      className="blogPostFeature"
      style={{
        backgroundImage: `url(${blogPost.image})`,
      }}
    >
      <div className="blogPostFeatureSpacer"></div>
      <div className="blogPostFeatureText">
        <h2 style={{ fontSize: "2rem" }}>{blogPost.title}</h2>
        <div
          style={{
            fontSize: "1.25rem",
            color: "LightGrey",
            height: "9rem",
          }}
        >
          {blogPost.shortText}
        </div>
        <div className="text-center">
          {`${callToAction} `}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-arrow-right"
            viewBox="0 0 16 16"
          >
            <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default BlogFeatureDisplay;
