import Footer from "./Footer";
import Header from "./Header";

const PageNotFound = () => {
  return (
    <>
      <Header />
      <div className="App-backdrop">
        <div style={{ fontSize: "20rem" }}>404</div>
        <div>Uh oh, that page doesn't exist!</div>
      </div>
      <Footer />
    </>
  );
};

export default PageNotFound;
