import { Navbar, Container, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import StateContext, { state } from "../store";
import { logout } from "./AdminLogin";
import logo from "../starworksLogo.png";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AdminHeader = () => {
  const state = useContext(StateContext);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Starworks Studios Admin";
  }, []);

  return (
    <Navbar
      expand="lg"
      className="bg-body-tertiary"
      data-bs-theme="dark"
      sticky="top"
    >
      <Container>
        <LinkContainer to="/admin">
          <Navbar.Brand>
            <img src={logo} className="Header-logo" alt="logo" />
            <span>Starworks Studios Admin</span>
          </Navbar.Brand>
        </LinkContainer>
        <span className="w10"></span>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/admin">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/">
              <Nav.Link>Back to Site</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/">
              <Nav.Link onClick={() => logout(state, navigate)}>
                Logout
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default AdminHeader;
