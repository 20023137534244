import { useEffect, useState } from "react";
import Header from "./Header";
import { blankBlogPost, getBlogPosts } from "../util";
import BlogListDisplay, { ListPosition } from "./BlogListDisplay";
import Footer from "./Footer";
import { useWindowDimensions } from "../hooks/useWindowDimensions";

const Blog = () => {
  const screenSize = useWindowDimensions();

  const [posts, setPosts] = useState([blankBlogPost]);
  const [postsDom, setPostsDom] = useState([<></>]);

  useEffect(() => {
    retrieveData();
  }, []);

  useEffect(() => {
    const dom = [<></>];
    let listPos: ListPosition = posts.length === 1 ? "alone" : "top";
    for (const i in posts) {
      const post = posts[i];
      dom.push(
        <BlogListDisplay
          blogPost={post}
          listPos={listPos}
          showImg={screenSize.width > 470}
        />
      );
      listPos = Number(i) === posts.length - 2 ? "bottom" : "inner";
    }
    setPostsDom(dom);
  }, [posts, screenSize]);

  async function retrieveData() {
    const blogList = await getBlogPosts();
    const sorted = blogList
      .filter((p) => p.published)
      .sort((a, b) => b.publishDate.getTime() - a.publishDate.getTime());
    setPosts(sorted);
  }

  return (
    <>
      <Header />
      <div className="App-backdrop dotted-backdrop">
        <div className="m-3">
          <h1 className="text-center">Blog</h1>
          <div style={{ width: screenSize.width > 990 ? "60vw" : "90vw" }}>
            {postsDom}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
