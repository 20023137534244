import { YoutubeEmbed } from "./YoutubeEmbed";

export const BlogPostImage = ({ src, alt }: { src?: string; alt?: string }) => {
  // Custom Youtube Video Embed feature
  if (src && src.startsWith("https://www.youtube.com")) {
    let idStart = src.indexOf("?v=") + 3;
    if (idStart < 3) {
      return (
        <code>
          ERROR: Ensure link is in the form
          https://www.youtube.com/watch?v=ID_HERE
        </code>
      );
    }
    let embedId = src.substring(idStart);
    return <YoutubeEmbed embedId={embedId} />;
  }

  // Center image and add caption using alt text
  return (
    <div className="d-flex flex-column justify-content-center">
      <img src={src} className="img-responsive blogPostImg" />
      <em className="text-center">{alt}</em>
    </div>
  );
};
