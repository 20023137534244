import { useNavigate } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import { useContext, useEffect, useState } from "react";
import StateContext from "../store";
import { authGuard } from "./AdminLogin";
import { Button, Form, Modal, Table } from "react-bootstrap";
import {
  blankUserBio,
  blankUserData,
  getMemberBio,
  getUsersData,
  stripUserData,
  updateUserData,
  Bio,
} from "../util";
import MemberBio from "./MemberBio";

const AdminBlogs = () => {
  const state = useContext(StateContext);
  const navigate = useNavigate();

  const [userData, setUserData] = useState([blankUserData]);
  const [tableRows, setTableRows] = useState([<></>]);
  const [selectedUser, setSelectedUser] = useState(blankUserData);
  const [selectedUserBio, setSelectedUserBio]: any = useState(null);
  const [saveText, setSaveText] = useState("Save");
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    authGuard(state, navigate).then(() => {
      if (!state.user || state.user.permissionLevel < 3) {
        navigate("/admin");
        return;
      }

      retrieveData();
    });
  }, []);

  useEffect(() => {
    const rows = [<></>];

    for (const userD of userData) {
      let styles: any = {};
      if (selectedUser.id === userD.id) {
        styles.backgroundColor = "#004e6c";
      }
      rows.push(
        <tr key={userD.id} onClick={() => setSelectedUser(userD)}>
          <td style={styles}>{userD.firstName}</td>
          <td style={styles}>{userD.lastName}</td>
          <td style={styles}>{userD.teamRole}</td>
        </tr>
      );
    }

    setTableRows(rows);
  }, [userData, selectedUser]);

  useEffect(() => {
    getBio(selectedUser.id);
  }, [selectedUser]);

  async function getBio(id: string) {
    if (!id || id === "") return;
    const bio = await getMemberBio(id);
    setSelectedUserBio(bio);
  }

  async function retrieveData() {
    const userList = await getUsersData();
    const selectId =
      selectedUser.id === "" ? state.user?.authUser.uid ?? "" : selectedUser.id;
    const newSelectUser = userList.find((ud) => ud.id === selectId);
    userList.sort((a, b) => b.permissionLevel - a.permissionLevel);
    setUserData(userList);
    if (newSelectUser) setSelectedUser(newSelectUser);
  }

  function changeUserAttribute(attr: string, value: any) {
    const copy: any = { ...selectedUser };
    copy[attr] = value;
    setSelectedUser(copy);
  }

  async function saveUser() {
    const id = selectedUser.id;
    if (!id || id === "") return;
    try {
      await updateUserData(id, stripUserData(selectedUser));
    } catch (e) {
      setShowErrorModal(true);
    }
    setSaveText("Saved");
    setTimeout(() => setSaveText("Save"), 3000);
    retrieveData();
  }

  return (
    <>
      <AdminHeader />
      <div className="App-backdrop">
        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
          <Modal.Header>Save Failed</Modal.Header>
          <Modal.Body>
            This can happen when you do not have permissions or entered invalid
            data.
          </Modal.Body>
        </Modal>
        <div className="m-3"></div>
        <div className="d-flex">
          <div style={{ width: "45vw" }}>
            <Table data-bs-theme="dark">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Team Role</th>
                </tr>
              </thead>
              <tbody>{tableRows}</tbody>
            </Table>
          </div>
          <div className="m-2" style={{ width: "45vw" }}>
            {selectedUserBio ? (
              <MemberBio bio={selectedUserBio} imgLeft={true} />
            ) : (
              <div>User Bio Unavailable</div>
            )}
            <Form.Label htmlFor="idInput">User ID</Form.Label>
            <Form.Control readOnly id="idInput" value={selectedUser.id} />
            <Form.Label htmlFor="firstNameInput">First Name</Form.Label>
            <Form.Control
              id="firstNameInput"
              value={selectedUser.firstName}
              onChange={(e) => {
                changeUserAttribute("firstName", e.target.value);
              }}
            />
            <Form.Label htmlFor="lastNameInput">Last Name</Form.Label>
            <Form.Control
              id="lastNameInput"
              value={selectedUser.lastName}
              onChange={(e) => {
                changeUserAttribute("lastName", e.target.value);
              }}
            />
            <Form.Label htmlFor="teamRoleInput">Team Role</Form.Label>
            <Form.Control
              id="teamRoleInput"
              value={selectedUser.teamRole}
              onChange={(e) => {
                changeUserAttribute("teamRole", e.target.value);
              }}
            />
            <Form.Label htmlFor="permLevelInput">Permission Level</Form.Label>
            <Form.Control
              id="permLevelInput"
              value={selectedUser.permissionLevel}
              onChange={(e) => {
                if (isNaN(Number(e.target.value))) return;
                changeUserAttribute("permissionLevel", Number(e.target.value));
              }}
            />
            <Form.Label htmlFor="notesInput">Notes</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              id="notesInput"
              value={selectedUser.notes}
              onChange={(e) => {
                changeUserAttribute("notes", e.target.value);
              }}
            />
            <Button onClick={saveUser}>{saveText}</Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminBlogs;
