import { Button, Table } from "react-bootstrap";
import AdminHeader from "./AdminHeader";
import { useContext, useEffect, useState } from "react";
import {
  BlogPost,
  blankBlogPost,
  createBlogPost,
  getAuthorDetails,
  getBlogPosts,
  listAuthors,
  updateBlogPost,
} from "../util";
import { useNavigate } from "react-router-dom";
import StateContext from "../store";
import { authGuard } from "./AdminLogin";

const AdminBlogs = () => {
  const state = useContext(StateContext);
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState([blankBlogPost]);
  const [tableRows, setTableRows] = useState([<></>]);

  useEffect(() => {
    authGuard(state, navigate).then(() => {
      retrieveData();
    });
  }, []);

  useEffect(() => {
    setupTable();
  }, [blogs]);

  async function retrieveData() {
    const blogList = await getBlogPosts();
    blogList.sort((a, b) => {
      if ((a.pinned && b.pinned) || (!a.pinned && !b.pinned))
        return b.creationDate.getTime() - a.creationDate.getTime();
      return a.pinned ? -1 : 1;
    });
    setBlogs(blogList);
  }

  async function setupTable() {
    const rows = [<></>];

    rows.push(
      <tr key={"create blog"}>
        <td>CREATE NEW POST</td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <Button className="m-1" onClick={createNewBlogPost}>
            Create
          </Button>
        </td>
      </tr>
    );

    for (const blog of blogs) {
      const authorLine = await listAuthors(blog.authors);
      const pinnedVariant = blog.pinned ? "success" : "primary";
      const featuredVariant = blog.featured ? "success" : "primary";
      rows.push(
        <tr key={blog.id}>
          <td>{blog.title}</td>
          <td>{authorLine}</td>
          <td>{blog.creationDate.toDateString()}</td>
          <td>{blog.published ? "true" : "false"}</td>
          <td>
            <Button
              className="m-1"
              onClick={() => navigate(`/admin/blogs/${blog.id}`)}
            >
              {canEditBlog(blog) ? "Edit" : "View"}
            </Button>
            {canPublishBlogs() && (
              <Button
                className="m-1"
                onClick={() => {
                  publishBlogPost(blog, !blog.published);
                }}
              >
                {blog.published ? "Hide" : "Publish"}
              </Button>
            )}
            {canEditBlog(blog) && (
              <Button
                variant={pinnedVariant}
                className="m-1"
                onClick={() => {
                  pinBlogPost(blog, !blog.pinned);
                }}
              >
                {blog.pinned ? "Pinned" : "Pin"}
              </Button>
            )}
            {canPublishBlogs() && (
              <Button
                variant={featuredVariant}
                className="m-1"
                onClick={() => {
                  featureBlogPost(blog, !blog.featured);
                }}
              >
                {blog.featured ? "Featured" : "Feature"}
              </Button>
            )}
          </td>
        </tr>
      );
    }

    setTableRows(rows);
  }

  function canEditBlog(blog: BlogPost) {
    const permissionLevel = state.user?.permissionLevel ?? 0;
    return (
      permissionLevel >= 2 ||
      (permissionLevel >= 1 &&
        blog.authors.includes(state.user?.authUser.uid ?? ""))
    );
  }

  function canPublishBlogs() {
    const permissionLevel = state.user?.permissionLevel ?? 0;
    return permissionLevel >= 2;
  }

  async function publishBlogPost(blog: BlogPost, show: boolean) {
    await updateBlogPost(blog.id, {
      published: show,
      publishDate: Date.now(),
    });
    retrieveData();
  }

  async function pinBlogPost(blog: BlogPost, pin: boolean) {
    await updateBlogPost(blog.id, { pinned: pin });
    retrieveData();
  }

  async function featureBlogPost(blog: BlogPost, feature: boolean) {
    await updateBlogPost(blog.id, { featured: feature });
    retrieveData();
  }

  async function createNewBlogPost() {
    const authorId = state.user?.authUser.uid;
    if (!authorId) return;
    const doc = await createBlogPost(authorId);
    navigate(`/admin/blogs/${doc.id}`);
  }

  return (
    <>
      <AdminHeader />
      <div className="App-backdrop">
        <div style={{ width: "90%" }}>
          <Table data-bs-theme="dark">
            <thead>
              <tr>
                <th>Title</th>
                <th>Author</th>
                <th>Created</th>
                <th>Published?</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </Table>
        </div>
      </div>
    </>
  );
};
export default AdminBlogs;
