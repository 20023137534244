import { useEffect, useState } from "react";
import { Bio, blankUserBio, getAuthorDetails } from "../util";

const Author = ({ id, bio: bioProp }: AuthorProps) => {
  const [bio, setBio] = useState(blankUserBio);

  useEffect(() => {
    if (id) getBio(id);
    else if (bioProp) setBio(bioProp);
  }, [id, bioProp]);

  async function getBio(id: string) {
    const author = await getAuthorDetails(id);
    setBio(author);
  }

  return (
    <div
      className={"authorComp d-flex"}
      style={{
        backgroundImage: `linear-gradient(to left, ${bio.color1}, ${bio.color2})`,
      }}
    >
      <img src={bio.image} className="authorImg" />
      <div style={{ maxWidth: "100%", overflow: "hidden" }}>
        <h4>{bio.displayName}</h4>
        <p
          style={{
            fontStyle: "italic",
            fontSize: "1rem",
            margin: "0px",
            whiteSpace: "nowrap",
          }}
        >
          {bio.teamRole}
        </p>
      </div>
    </div>
  );
};
export default Author;

type AuthorProps = { id?: string; bio?: Bio };
