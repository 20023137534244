import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StateContext from "../store";
import AdminHeader from "./AdminHeader";
import ReactMarkdown from "react-markdown";
import { authGuard } from "./AdminLogin";
import {
  BlogPost,
  blankBlogPost,
  getBlogPost,
  stripBlogPost,
  updateBlogPost,
} from "../util";
import { Button, Form } from "react-bootstrap";
import Author from "./Author";
import BlogPostDisplay from "./BlogPostDisplay";
import BlogListDisplay from "./BlogListDisplay";
import BlogFeatureDisplay from "./BlogFeatureDisplay";

const AdminBlogEdit = () => {
  const { id } = useParams();
  const state = useContext(StateContext);
  const navigate = useNavigate();

  const [blogPost, setBlogPost] = useState(blankBlogPost);
  const [canEdit, setCanEdit] = useState(false);
  const [editAuthorsDom, setEditAuthorsDom] = useState([<></>]);
  const [previewAuthorsDom, setPreviewAuthorsDom] = useState([<></>]);
  const [newAuthor, setNewAuthor] = useState("");
  const [saveText, setSaveText] = useState("Save");

  useEffect(() => {
    authGuard(state, navigate).then(async () => {
      if (!id) {
        navigate("/404");
        return;
      }
      const blog = await getBlogPost(id);
      if (!blog) {
        navigate("/404");
        return;
      }
      setBlogPost(blog);
      setCanEdit(canEditBlog(blog));
    });
  }, []);

  useEffect(() => {
    const editDom = [<></>];
    const previewDom = [<></>];

    for (const author of blogPost.authors) {
      editDom.push(
        <div className="d-flex">
          <Author id={author} />
          {canEdit && (
            <Button
              variant="danger"
              style={{ margin: `2rem 1rem` }}
              onClick={() => removeAuthor(author)}
            >
              Remove
            </Button>
          )}
        </div>
      );

      previewDom.push(<Author id={author} />);
    }

    setEditAuthorsDom(editDom);
    setPreviewAuthorsDom(previewDom);
  }, [blogPost, canEdit]);

  function canEditBlog(blog: BlogPost) {
    const permissionLevel = state.user?.permissionLevel ?? 0;
    return (
      permissionLevel >= 2 ||
      (permissionLevel >= 1 &&
        blog.authors.includes(state.user?.authUser.uid ?? ""))
    );
  }

  function changeBlogAttribute(attr: string, value: any) {
    const copy: any = { ...blogPost };
    copy[attr] = value;
    setBlogPost(copy);
  }

  function removeAuthor(id: string) {
    const removeIdx = blogPost.authors.indexOf(id);
    if (removeIdx >= 0) {
      const newAuthors = [...blogPost.authors];
      newAuthors.splice(removeIdx, 1);
      changeBlogAttribute("authors", newAuthors);
    }
  }

  function addAuthor(id: string) {
    if (blogPost.authors.includes(id)) return;
    const newAuthors = [...blogPost.authors];
    newAuthors.push(id);
    changeBlogAttribute("authors", newAuthors);
  }

  async function saveBlogPost() {
    if (!id) return;
    await updateBlogPost(id, stripBlogPost(blogPost));
    setSaveText("Saved");
    setTimeout(() => setSaveText("Save"), 3000);
  }

  return (
    <>
      <AdminHeader />
      <div className="App-backdrop">
        <div className="m-3"></div>
        <div className="d-flex">
          <div style={{ width: "45vw" }}>
            <h1 style={{ textAlign: "center" }}>Preview</h1>
            <h2>List View</h2>
            <BlogListDisplay blogPost={blogPost} />
            <h2>Feature View</h2>
            <BlogFeatureDisplay blogPost={blogPost} />
            <h2>Post View</h2>
            <BlogPostDisplay blogPost={blogPost} />
          </div>
          <div className="m-3"></div>
          <div style={{ width: "45vw" }}>
            <h1 style={{ textAlign: "center" }}>Edit</h1>
            {!canEdit && (
              <>
                <em>You do not have permissions to edit this blog post.</em>
                <br />
              </>
            )}
            <Form.Label htmlFor="idInput">Blog ID</Form.Label>
            <Form.Control readOnly id="idInput" value={blogPost.id} />
            <Form.Label htmlFor="creationInput">Creation Date</Form.Label>
            <Form.Control
              readOnly
              id="creationInput"
              value={`${blogPost.creationDate.toDateString()} ${blogPost.creationDate.toTimeString()}`}
            />
            {blogPost.published && (
              <>
                <Form.Label htmlFor="publishDateInput">Publish Date</Form.Label>
                <Form.Control
                  readOnly
                  id="publishDateInput"
                  value={`${blogPost.publishDate.toDateString()} ${blogPost.publishDate.toTimeString()}`}
                />
              </>
            )}
            <Form.Label htmlFor="titleInput">Title</Form.Label>
            <Form.Control
              readOnly={!canEdit}
              id="titleInput"
              value={blogPost.title}
              onChange={(e) => {
                changeBlogAttribute("title", e.target.value);
              }}
            />
            <h2>Author(s)</h2>
            {editAuthorsDom}
            {canEdit && (
              <>
                <Form.Label htmlFor="authorInput">New Author</Form.Label>
                <Form.Control
                  readOnly={!canEdit}
                  id="authorInput"
                  value={newAuthor}
                  onChange={(e) => {
                    setNewAuthor(e.target.value);
                  }}
                />
              </>
            )}
            <Button
              className="m-1"
              onClick={() => {
                addAuthor(newAuthor);
                setNewAuthor("");
              }}
            >
              Add
            </Button>
            <Button
              className="m-1"
              onClick={() => {
                if (!state.user) return;
                addAuthor(state.user.authUser.uid);
              }}
            >
              Add Self
            </Button>
            <br />
            <Form.Label htmlFor="shortTextInput">Short Text</Form.Label>
            <Form.Control
              readOnly={!canEdit}
              as="textarea"
              id="shortTextInput"
              value={blogPost.shortText}
              onChange={(e) => {
                changeBlogAttribute("shortText", e.target.value);
              }}
            />
            <Form.Label htmlFor="imageInput">Preview Image</Form.Label>
            <Form.Control
              readOnly={!canEdit}
              id="imageInput"
              value={blogPost.image}
              onChange={(e) => {
                changeBlogAttribute("image", e.target.value);
              }}
            />
            <Form.Label htmlFor="contentInput">Content</Form.Label>
            <Form.Control
              readOnly={!canEdit}
              as="textarea"
              id="contentInput"
              value={blogPost.content}
              onChange={(e) => {
                changeBlogAttribute("content", e.target.value);
              }}
            />
            <Button onClick={saveBlogPost}>{saveText}</Button>
            <div className="m-5"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminBlogEdit;
