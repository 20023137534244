import { useContext, useEffect, useState } from "react";
import { authGuard } from "./AdminLogin";
import { useLocation, useNavigate } from "react-router-dom";
import StateContext from "../store";

const adminPrefix = "admin";
const demoPrefix = "demo";

const Wrapper = ({ children }: any) => {
  const state = useContext(StateContext);
  const navigate = useNavigate();
  const loc = useLocation();

  const [checksDone, setChecksDone] = useState(false);

  useEffect(() => {
    if (window.location.host.split(".")[0] === adminPrefix) {
      let adminRedirect = window.location.host;
      const cut = adminRedirect.indexOf(".");
      adminRedirect = adminRedirect.slice(cut + 1);
      window.location.href = `https://${adminRedirect}/admin`;
      return;
    }

    if (window.location.host.split(".")[0] === demoPrefix) {
      if (loc.pathname.endsWith("/login")) {
        setChecksDone(true);
        return;
      }

      authGuard(state, navigate).then(() => {
        if (!state.user || state.user.permissionLevel < 1) {
          let demoRedirect = window.location.host;
          const cut = demoRedirect.indexOf(".");
          demoRedirect = demoRedirect.slice(cut + 1);
          window.location.href = `https://${demoRedirect}/`;
          return;
        } else {
          setChecksDone(true);
        }
      });
    } else {
      setChecksDone(true);
    }
  }, []);

  return <>{checksDone && children}</>;
};

export default Wrapper;
