import { useNavigate } from "react-router-dom";
import { BlogPost, getAuthorDetails, listAuthors } from "../util";
import { type } from "os";
import { useEffect, useState } from "react";

export type ListPosition = "alone" | "top" | "bottom" | "inner";

const BlogListDisplay = ({
  blogPost,
  listPos = "alone",
  showImg = true,
}: {
  blogPost: BlogPost;
  listPos?: ListPosition;
  showImg?: boolean;
}) => {
  const navigate = useNavigate();

  const [authorLine, setAuthorLine] = useState("");

  useEffect(() => {
    listAuthors(blogPost.authors).then((line) => {
      setAuthorLine(line);
    });
  }, [blogPost.authors]);

  return (
    <div
      onClick={() => navigate(`/blog/${blogPost.id}`)}
      className={`blogPostList ${listPos}`}
    >
      {listPos != "alone" && listPos != "top" && (
        <div
          style={{
            margin: "0rem 1rem",
            height: "1px",
            backgroundColor: "gray",
          }}
        ></div>
      )}
      <div className="d-flex">
        {showImg && (
          <img
            src={blogPost.image}
            style={{
              height: "12rem",
              width: "16rem",
              objectFit: "cover",
              padding: "1rem",
              borderRadius: "2rem",
            }}
          />
        )}
        <div
          style={{
            padding: showImg ? "1rem 1rem 1rem 0rem" : "1rem",
            flexGrow: 1,
          }}
        >
          <div>{blogPost.title}</div>
          <div
            style={{
              fontSize: "1.25rem",
              color: "LightGrey",
              height: "5.5rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {blogPost.shortText}
          </div>
          <div className="d-flex">
            <div style={{ fontSize: "1rem", color: "LightGrey" }}>
              By {authorLine}
            </div>
            <div
              style={{
                fontSize: "1rem",
                color: "LightGrey",
                textAlign: "right",
                flexGrow: 1,
              }}
            >
              Posted on {blogPost.publishDate.toDateString()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogListDisplay;
