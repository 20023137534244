import { useState, useEffect, useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { NavigateFunction, useNavigate } from "react-router-dom";
import StateContext, { GlobalState } from "../store";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, provider } from "../firebase";
import { StarworksUser } from "../util";
import AdminHeader from "./AdminHeader";

const AdminLogin = () => {
  const state = useContext(StateContext);
  const navigate = useNavigate();

  const [showLogin, setShowLogin] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showPermissionWarn, setShowPermissionWarn] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [teamRole, setTeamRole] = useState("");

  const initialCheck = async () => {
    await auth.authStateReady();
    if (auth.currentUser && !state.user) {
      const user = new StarworksUser(auth.currentUser);
      await user.loadUserData();
      state.setUser(user);
    }

    if (!state.user) {
      setShowLogin(true);
      return;
    }
    if (state.user.permissionLevel < 0) {
      setShowCreate(true);
      return;
    }
    if (state.user.permissionLevel === 0) {
      setShowPermissionWarn(true);
      return;
    }
    navigate("/admin");
  };

  useEffect(() => {
    initialCheck();
  }, []);

  const handleClose = () => navigate("/");

  const googleSignIn = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (!credential) return;
        const token = credential.accessToken;

        // The signed-in user info.
        const user = new StarworksUser(result.user);
        await user.loadUserData();
        state.setUser(user);
        navigate("/admin");
      })
      .catch((error) => {
        console.log(error);
        navigate("/");
      });
  };

  const userDataSubmit = async () => {
    if (!state.user) navigate("/admin");
    await state.user?.updateUserData({
      firstName: firstName,
      lastName: lastName,
      teamRole: teamRole,
    });
    navigate("/admin");
  };

  return (
    <>
      <AdminHeader />
      <div className="App-backdrop">
        <Modal show={showLogin} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Login Required</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Only Starworks staff members are given accounts. Manual review is
              required for new accounts. Please only sign in if you are a staff
              member.
            </p>
            <Button variant="primary" onClick={googleSignIn}>
              Sign In With Google
            </Button>
          </Modal.Body>
        </Modal>
        <Modal show={showCreate} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2>Please enter some more details to help identify yourself.</h2>
            <h3>
              Accounts are reserved for Starworks Staff only, and must go
              through a review process.
            </h3>
            <Form.Label htmlFor="firstNameInput">First Name</Form.Label>
            <Form.Control
              id="firstNameInput"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Form.Label htmlFor="lastNameInput">Last Name</Form.Label>
            <Form.Control
              id="lastNameInput"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <Form.Label htmlFor="roleInput">Team Role</Form.Label>
            <Form.Control
              id="roleInput"
              value={teamRole}
              onChange={(e) => setTeamRole(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={userDataSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showPermissionWarn} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Insufficient Permissions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            A member of Starworks staff must review your account and approve it
            before access is granted.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AdminLogin;

export const authGuard = async (
  state: GlobalState,
  navigate: NavigateFunction
) => {
  await auth.authStateReady();
  if (auth.currentUser && !state.user) {
    const user = new StarworksUser(auth.currentUser);
    await user.loadUserData();
    state.setUser(user);
  }
  if (!state.user || state.user.permissionLevel <= 0) navigate("/admin/login");
};

export const logout = async (state: any, navigate: NavigateFunction) => {
  await auth.signOut();
  state.setUser(null);
  navigate("/admin/login");
};
