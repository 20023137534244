import { useNavigate, useParams } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import { useContext, useEffect, useState } from "react";
import { authGuard } from "./AdminLogin";
import StateContext from "../store";
import {
  blankUserBio,
  getMemberBio,
  starworksLogoLink,
  stripBioData,
  updateMemberBio,
  Bio,
  BioLink,
} from "../util";
import MemberBio from "./MemberBio";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import Author from "./Author";

const AdminBioEdit = () => {
  const { id } = useParams();
  const state = useContext(StateContext);
  const navigate = useNavigate();

  const [previewBio, setPreviewBio] = useState(blankUserBio);
  const [saveText, setSaveText] = useState("Save");

  const [bioLinkForms, setBioLinkForms] = useState([<></>]);

  useEffect(() => {
    authGuard(state, navigate).then(async () => {
      if (!id) {
        navigate("/404");
        return;
      }
      const bio = await getMemberBio(id);
      if (!bio) {
        navigate("/404");
        return;
      }
      setPreviewBio(bio);
      // setupBioLinkForms(bio);
    });
  }, []);

  useEffect(() => {
    const linkForms = previewBio.links.map((link, i) => (
      <div
        key={`link${i}`}
        className="m-2 p-2"
        style={{ backgroundColor: "#383e48" }}
      >
        <Form.Label htmlFor={`link${i}UrlInput`}>Link URL</Form.Label>
        <Form.Control
          id={`link${i}UrlInput`}
          value={link.url}
          onChange={(e) => {
            changeBioLinks(i, "url", e.target.value);
          }}
        />
        <Form.Label htmlFor={`link${i}IconInput`}>Icon URL</Form.Label>
        <Form.Control
          id={`link${i}IconInput`}
          value={link.icon}
          onChange={(e) => {
            changeBioLinks(i, "icon", e.target.value);
          }}
        />
        <Form.Label htmlFor={`link${i}InvertInput`}>Invert Colors?</Form.Label>
        <Form.Check
          id={`link${i}InvertInput`}
          checked={link.invert}
          onChange={(e) => {
            changeBioLinks(i, "invert", e.target.checked);
          }}
        />
        <Button variant="danger" onClick={() => removeBioLink(i)}>
          Remove Link
        </Button>
      </div>
    ));
    setBioLinkForms(linkForms);
  }, [previewBio]);

  function changeBioAttribute(attr: string, value: any) {
    const copy: any = { ...previewBio };
    copy[attr] = value;
    setPreviewBio(copy);
  }

  function changeBioLinks(index: number, attr: string, value: any) {
    const copy: any = [...previewBio.links];
    copy[index][attr] = value;
    changeBioAttribute("links", copy);
  }

  function addBioLink() {
    const copy = [...previewBio.links];
    copy.push(new BioLink("/", starworksLogoLink(), false));
    changeBioAttribute("links", copy);
  }

  function removeBioLink(index: number) {
    const copy = [...previewBio.links];
    copy.splice(index, 1);
    changeBioAttribute("links", copy);
  }

  async function saveBio() {
    if (!id) return;
    await updateMemberBio(id, stripBioData(previewBio));
    setSaveText("Saved");
    setTimeout(() => setSaveText("Save"), 3000);
  }

  return (
    <>
      <AdminHeader />
      <div className="App-backdrop">
        <div className="m-3"></div>
        <div className="d-flex">
          <div style={{ width: "45vw" }}>
            <h1 style={{ textAlign: "center" }}>Preview</h1>
            <MemberBio bio={previewBio} imgLeft={true} />
            <Author bio={previewBio} />
          </div>
          <div style={{ width: "45vw" }}>
            <h1 style={{ textAlign: "center" }}>Edit</h1>
            <Form.Label htmlFor="idInput">Bio ID</Form.Label>
            <Form.Control readOnly id="idInput" value={previewBio.docId} />
            <Form.Label htmlFor="displayNameInput">Display Name</Form.Label>
            <Form.Control
              id="displayNameInput"
              value={previewBio.displayName}
              onChange={(e) => {
                changeBioAttribute("displayName", e.target.value);
              }}
            />
            <Form.Label htmlFor="teamRoleInput">Team Role</Form.Label>
            <Form.Control
              id="teamRoleInput"
              value={previewBio.teamRole}
              onChange={(e) => {
                changeBioAttribute("teamRole", e.target.value);
              }}
            />
            <Form.Label htmlFor="descriptionInput">Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              id="descriptionInput"
              value={previewBio.description}
              onChange={(e) => {
                changeBioAttribute("description", e.target.value);
              }}
            />
            <Form.Label htmlFor="imageInput">Image Link</Form.Label>
            <Form.Control
              id="imageInput"
              value={previewBio.image}
              onChange={(e) => {
                changeBioAttribute("image", e.target.value);
              }}
            />
            <Form.Label htmlFor="color1Input">Color 1</Form.Label>
            <Form.Control
              id="color1Input"
              type="color"
              value={previewBio.color1}
              onChange={(e) => {
                changeBioAttribute("color1", e.target.value);
              }}
            />
            <Form.Label htmlFor="color2Input">Color 2</Form.Label>
            <Form.Control
              id="color2Input"
              type="color"
              value={previewBio.color2}
              onChange={(e) => {
                changeBioAttribute("color2", e.target.value);
              }}
            />
            <details title="Links">
              <summary>Links</summary>
              {bioLinkForms}
              <Button onClick={() => addBioLink()}>Add New Link</Button>
            </details>
            <Button onClick={saveBio}>{saveText}</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminBioEdit;
