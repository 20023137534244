import { createContext, useEffect, useState } from "react";
import { StarworksUser } from "./util";

export type GlobalState = {
  user: undefined | StarworksUser;
  setUser: (user: StarworksUser) => void;
};

export const state: GlobalState = {
  user: undefined,
  setUser: () => {},
};

let localState: GlobalState = {
  user: undefined,
  setUser: () => {},
};

const StateContext = createContext(state);

export const StateContextProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  useEffect(() => {
    localState = initialState;
  }, []);

  const setUser = (user: StarworksUser) => {
    localState.user = user;
    setState({ ...localState });
  };

  const initialState: GlobalState = {
    user: undefined,
    setUser: setUser,
  };

  const [stateH, setState] = useState(initialState);
  return (
    <StateContext.Provider value={stateH}>{children}</StateContext.Provider>
  );
};

export default StateContext;
