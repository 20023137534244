import { useState, useEffect, useContext } from "react";
import { Card, CardImg, Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import StateContext from "../store";
import { authGuard, logout } from "./AdminLogin";
import { LinkContainer } from "react-router-bootstrap";
import logo from "../starworksLogo.png";
import userIcon from "../assets/users.png";
import blogIcon from "../assets/blog.png";
import bioIcon from "../assets/bio.png";
import AdminHeader from "./AdminHeader";

const AdminHome = () => {
  const state = useContext(StateContext);
  const navigate = useNavigate();

  const [manageButton, setManageButton] = useState(<></>);

  useEffect(() => {
    authGuard(state, navigate)
      .then(() => {
        if (state.user && state.user.permissionLevel >= 3) {
          setManageButton(
            <LinkContainer
              to="/admin/users"
              style={{ width: "20rem", cursor: "pointer" }}
            >
              <Card data-bs-theme="dark">
                <CardImg
                  variant="top"
                  src={userIcon}
                  className="inverted adminCardIcon"
                />
                <Card.Title className="text-center" as="h3">
                  Manage Users
                </Card.Title>
              </Card>
            </LinkContainer>
          );
        }
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <AdminHeader />
      <div className="App-backdrop vertical-center">
        <div className="d-flex" style={{ gap: "1rem" }}>
          {manageButton}
          <LinkContainer
            to="/admin/blogs"
            style={{ width: "20rem", cursor: "pointer" }}
          >
            <Card data-bs-theme="dark">
              <CardImg
                variant="top"
                src={blogIcon}
                className="inverted adminCardIcon"
              />
              <Card.Title className="text-center" as="h3">
                Blog Posts
              </Card.Title>
            </Card>
          </LinkContainer>
          <LinkContainer
            to="/admin/bios"
            style={{ width: "20rem", cursor: "pointer" }}
          >
            <Card data-bs-theme="dark">
              <CardImg
                variant="top"
                src={bioIcon}
                className="inverted adminCardIcon"
              />
              <Card.Title className="text-center" as="h3">
                User Bios
              </Card.Title>
            </Card>
          </LinkContainer>
        </div>
      </div>
    </>
  );
};

export default AdminHome;
