import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../starworksLogo.png";
import { LinkContainer } from "react-router-bootstrap";
import { useEffect } from "react";
import { StarworksButton } from "./StarworksButton";
import { Link } from "react-router-dom";

const Header = () => {
  useEffect(() => {
    document.title = "Starworks Studios";
  }, []);

  return (
    <Navbar
      expand="lg"
      className="color-header"
      data-bs-theme="dark"
      sticky="top"
    >
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img src={logo} className="Header-logo" alt="logo" />
          </Navbar.Brand>
        </LinkContainer>
        <span className="w10"></span>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="me-auto"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Link to="/" className="no-underline"></Link>
            <a
              className="no-underline"
              href="https://store.steampowered.com/app/2667160/Timeworks/"
              target="_blank"
            >
              <StarworksButton>Timeworks</StarworksButton>
            </a>
            <Link to="/blog" className="no-underline">
              <StarworksButton>Blog</StarworksButton>
            </Link>
            <Link to="/team" className="no-underline">
              <StarworksButton>About Us</StarworksButton>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
