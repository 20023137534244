import { useEffect, useState } from "react";
import { BlogPost } from "../util";
import Author from "./Author";
import ReactMarkdown from "react-markdown";
import { BlogPostImage } from "./BlogPostImage";

const BlogPostDisplay = ({ blogPost }: { blogPost: BlogPost }) => {
  const [authorsDom, setAuthorsDom] = useState([<></>]);

  useEffect(() => {
    const dom = [<></>];
    for (const author of blogPost.authors) {
      dom.push(<Author id={author} />);
    }
    setAuthorsDom(dom);
  }, [blogPost]);

  return (
    <>
      <h1 style={{ fontSize: "3rem" }}>{blogPost.title}</h1>
      <div className="d-flex">{authorsDom}</div>
      <p style={{ fontSize: "1.5rem" }}>
        {blogPost.publishDate.toDateString()}
      </p>

      <ReactMarkdown
        className="blogPost"
        components={{
          img(props) {
            return <BlogPostImage src={props.src} alt={props.alt} />;
          },
        }}
      >
        {`---\n${blogPost.content}`}
      </ReactMarkdown>
    </>
  );
};

export default BlogPostDisplay;
