import AdminHeader from "./AdminHeader";
import { Button, Table } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import {
  createMemberBio,
  getMemberBios,
  updateMemberBio,
  Bio,
  BioLink,
} from "../util";
import StateContext from "../store";
import { useNavigate } from "react-router-dom";
import { authGuard } from "./AdminLogin";

const AdminBios = () => {
  const state = useContext(StateContext);
  const navigate = useNavigate();
  const [tableRows, setTableRows] = useState([<></>]);

  useEffect(() => {
    authGuard(state, navigate).then(() => {
      setupTable();
    });
  }, []);

  async function setupTable() {
    const rows = [<></>];

    const bioList = await getMemberBios();
    bioList.sort((a, b) => a.order - b.order);

    if (
      (state.user?.permissionLevel ?? 0) >= 2 &&
      bioList.filter((v) => v.docId === (state.user?.authUser.uid ?? null))
        .length === 0
    ) {
      rows.push(
        <tr key="create my bio">
          <td>CREATE MY BIO</td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <Button className="m-1" onClick={() => createNewBio(true)}>
              Create
            </Button>
          </td>
        </tr>
      );
    }

    if ((state.user?.permissionLevel ?? 0) >= 3) {
      rows.push(
        <tr key="create new bio">
          <td>CREATE NEW BIO</td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <Button className="m-1" onClick={() => createNewBio(false)}>
              Create
            </Button>
          </td>
        </tr>
      );
    }

    for (const bio of bioList) {
      rows.push(
        <tr key={bio.docId}>
          <td title={bio.docId}>
            {bio.docId.slice(0, 10) + (bio.docId.length > 10 ? "..." : "")}
          </td>
          <td>{bio.displayName}</td>
          <td>{bio.teamRole}</td>
          <td>{bio.show ? "true" : "false"}</td>
          <td>
            {canEditBio(bio.docId) && (
              <Button
                className="m-1"
                onClick={() => navigate(`/admin/bios/${bio.docId}`)}
              >
                Edit
              </Button>
            )}
            {canReorderBios() && (
              <Button
                className="m-1"
                onClick={() => {
                  reorderBios(bioList, bio, true);
                }}
              >
                Move Up
              </Button>
            )}
            {canReorderBios() && (
              <Button
                className="m-1"
                onClick={() => {
                  reorderBios(bioList, bio, false);
                }}
              >
                Move Down
              </Button>
            )}
            {canReorderBios() && (
              <Button
                className="m-1"
                onClick={() => showHideBio(bio.docId, !bio.show)}
              >
                Show/Hide
              </Button>
            )}
          </td>
        </tr>
      );
    }
    setTableRows(rows);
  }

  function canEditBio(bioId: string) {
    const permissionLevel = state.user?.permissionLevel ?? 0;
    return (
      permissionLevel >= 3 ||
      (permissionLevel >= 2 && bioId === (state.user?.authUser.uid ?? null))
    );
  }

  function canReorderBios() {
    const permissionLevel = state.user?.permissionLevel ?? 0;
    return permissionLevel >= 3;
  }

  async function createNewBio(forSelf: boolean) {
    let id = state.user?.authUser.uid;
    if (!id || !forSelf) {
      const rand = Math.floor(Math.random() * 10000);
      id = `fake_${rand}`;
    }
    await createMemberBio(id);
    navigate(`/admin/bios/${id}`);
  }

  async function showHideBio(id: string, show: boolean) {
    await updateMemberBio(id, {
      show: show,
    });
    setupTable();
  }

  async function reorderBios(bios: Bio[], bio: Bio, up: boolean) {
    const index = bios.indexOf(bio);
    const newIndex = index + (up ? -1 : 1);
    if (index === 0 && up) return;
    if (index === bios.length - 1 && !up) return;
    bios.splice(index, 1);
    bios.splice(newIndex, 0, bio);
    for (const i in bios) {
      const idx = Number(i);
      if (bios[i].order != idx) {
        await updateMemberBio(bios[i].docId, { order: idx });
      }
    }
    setupTable();
  }

  return (
    <>
      <AdminHeader />
      <div className="App-backdrop">
        <div style={{ width: "90%" }}>
          <Table data-bs-theme="dark">
            <thead>
              <tr>
                <th>ID</th>
                <th>Display Name</th>
                <th>Team Role</th>
                <th>Showing</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </Table>
        </div>
      </div>
    </>
  );
};
export default AdminBios;
