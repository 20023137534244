export const StarworksButton = ({ children }: StarworksButtonProps) => {
  return (
    <span className="starworks-button">
      <span>{children}</span>
    </span>
  );
};

type StarworksButtonProps = {
  children: string;
};
