import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { blankBlogPost, getBlogPost } from "../util";
import Header from "./Header";
import BlogPostDisplay from "./BlogPostDisplay";
import Footer from "./Footer";
import { useWindowDimensions } from "../hooks/useWindowDimensions";

const BlogPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const screenSize = useWindowDimensions();

  const [blogPost, setBlogPost] = useState(blankBlogPost);

  useEffect(() => {
    retrieveData();
  }, []);

  async function retrieveData() {
    if (!id) {
      navigate("/404");
      return;
    }
    const blog = await getBlogPost(id);
    if (!blog) {
      navigate("/404");
      return;
    }
    setBlogPost(blog);
  }

  return (
    <>
      <Header />
      <div className="App-backdrop">
        <div style={{ width: screenSize.width > 990 ? "60vw" : "90vw" }}>
          <BlogPostDisplay blogPost={blogPost} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogPost;
