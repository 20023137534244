import mascot from "../assets/sams3printsCrop.png";
import timeworksLogo from "../assets/timeworkslogo.png";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { EmailCollect } from "./EmailCollect";
import { StarworksButton } from "./StarworksButton";
import { YoutubeEmbed } from "./YoutubeEmbed";
import steamButton from "../assets/steam-store.png";

const LandingPageDesktop = () => {
  return (
    <div
      className="d-flex dotted-backdrop"
      style={{ gap: "1rem", padding: "2rem 10%", width: "100%" }}
    >
      <div style={{ flex: "1" }}>
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <img
            src={mascot}
            className="img-responsive"
            style={{ maxWidth: "560px" }}
          />
        </div>
        <div className="text-center" style={{ fontSize: "1.5rem" }}>
          Sign up to stay up to date!
        </div>
        <EmailCollect />
      </div>
      <div style={{ flex: "1" }}>
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <img
            src={timeworksLogo}
            className="img-responsive"
            style={{ maxWidth: "550px" }}
          />
        </div>
        <YoutubeEmbed embedId="Ucag_S8tFW4" />
        <div
          className="d-flex"
          style={{ justifyContent: "center", padding: "1rem" }}
        >
          <a
            className="no-underline d-flex"
            style={{ justifyContent: "center" }}
            href="https://store.steampowered.com/app/2667160/Timeworks/"
            target="_blank"
          >
            <img
              src={steamButton}
              className="img-resposive"
              style={{ maxWidth: "50%" }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

const LandingPageMobile = () => {
  return (
    <div
      className="d-flex dotted-backdrop"
      style={{
        flexDirection: "column",
        gap: "1rem",
        padding: "2rem 10%",
        width: "100%",
      }}
    >
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <img
          src={timeworksLogo}
          className="img-responsive"
          style={{ maxWidth: "550px" }}
        />
      </div>
      <div className="text-center" style={{ fontSize: "1.5rem" }}>
        Sign up to stay up to date!
      </div>
      <EmailCollect />
      <div
        className="d-flex"
        style={{ justifyContent: "center", padding: "1rem" }}
      >
        <a
          className="no-underline d-flex"
          style={{ justifyContent: "center" }}
          href="https://store.steampowered.com/app/2667160/Timeworks/"
          target="_blank"
        >
          <img
            src={steamButton}
            className="img-resposive"
            style={{ maxWidth: "75%" }}
          />
        </a>
      </div>
      <YoutubeEmbed embedId="Ucag_S8tFW4" />
    </div>
  );
};

export const LandingPage = () => {
  const screenSize = useWindowDimensions();

  if (screenSize.width < 990) return <LandingPageMobile />;
  return <LandingPageDesktop />;
};
