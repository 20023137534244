import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StateContextProvider } from "./store";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  AdminBioEdit,
  AdminBios,
  AdminBlogs,
  AdminHome,
  AdminLogin,
  AdminUsers,
  Blog,
  BlogPost,
  PageNotFound,
  Starhound,
  Team,
  Timeworks,
} from "./components";
import AdminBlogEdit from "./components/AdminBlogEdit";
import Wrapper from "./components/Wrapper";
import Contact from "./components/Contact";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <StateContextProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Wrapper>
                <App />
              </Wrapper>
            }
          ></Route>
          <Route
            path="/admin"
            element={
              <Wrapper>
                <AdminHome />
              </Wrapper>
            }
          ></Route>
          <Route
            path="/admin/login"
            element={
              <Wrapper>
                <AdminLogin />
              </Wrapper>
            }
          ></Route>
          <Route
            path="/admin/users"
            element={
              <Wrapper>
                <AdminUsers />
              </Wrapper>
            }
          ></Route>
          <Route
            path="/admin/blogs"
            element={
              <Wrapper>
                <AdminBlogs />
              </Wrapper>
            }
          ></Route>
          <Route
            path="/admin/blogs/:id"
            element={
              <Wrapper>
                <AdminBlogEdit />
              </Wrapper>
            }
          ></Route>
          <Route
            path="/admin/bios"
            element={
              <Wrapper>
                <AdminBios />
              </Wrapper>
            }
          ></Route>
          <Route
            path="/admin/bios/:id"
            element={
              <Wrapper>
                <AdminBioEdit />
              </Wrapper>
            }
          ></Route>
          <Route
            path="/blog"
            element={
              <Wrapper>
                <Blog />
              </Wrapper>
            }
          ></Route>
          <Route
            path="/blog/:id"
            element={
              <Wrapper>
                <BlogPost />
              </Wrapper>
            }
          ></Route>
          <Route
            path="/games"
            element={<Navigate to="/" replace={true} />}
          ></Route>
          <Route
            path="/games/Starhound"
            element={
              <Wrapper>
                <Starhound />
              </Wrapper>
            }
          ></Route>
          <Route
            path="/games/Timeworks"
            element={
              <Wrapper>
                <Timeworks />
              </Wrapper>
            }
          ></Route>
          <Route
            path="/Team"
            element={
              <Wrapper>
                <Team />
              </Wrapper>
            }
          ></Route>
          <Route
            path="/Contact"
            element={
              <Wrapper>
                <Contact />
              </Wrapper>
            }
          ></Route>
          <Route
            path="/404"
            element={
              <Wrapper>
                <PageNotFound />
              </Wrapper>
            }
          ></Route>
          <Route
            path="*"
            element={<Navigate to="/404" replace={true} />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </StateContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
